<template>
  <div>
    <button @click="changeChart" class="btn btn-success btn-sm mb-3">      
      {{ change_chart ?  $t("Ekip Grafiği") : $t("Müşteri Grafiği") }}
    </button>
    <p class="second-quality-title mb-0 pb-0 d-flex mx-3">
      {{ change_chart ?  $t("Müşteri Kar-Zarar Oranı") : $t("Ekip Kar-Zarar Oranı") }}
      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!change_chart">
      <div
        v-if="!chartLoading"
        v-on:click="resizeBool ? null : resize()"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptions"
          :series="series"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
    <div v-if="change_chart">
      <div
        v-if="!chartLoading"
        v-on:click="resizeBool ? null : resize()"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptionsCompany"
          :series="seriesCompany"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "ProfitAndLossPercentBarReport",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      chartCategories: [],
      chartCategoriesCompany: [],
      chartItems: [],
      chartItemsCompany: [],
      resizeBool: null,
      tmpSeries: [],
      series: [],
      seriesCompany: [],
      labels: [],
      height: 350,
      tmpWidth: null,
      chartLoading: false,
      change_chart:false,
    };
  },

  props: ["startDate", "endDate", "onClickButton", "filters"
    ,"currencyRateOptions", ],
  mounted() {
    this.getItems();
  },
  computed: {
    chartOptions() {
      let self = this;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth / 2;
      } else {
        this.tmpWidth = window.innerWidth / 3;
      }
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          bar: {
            horizontal: true,
            columnWidth:'80%',
            dataLabels: {
              position: "top",
            },
          },
        },        
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        dataLabels: { 
          position: 'top',
          enabled: true,
          offsetX: 0,
          formatter: function (val) { 
            return val.toLocaleString("tr-TR", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })  + "%"
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true, 
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,  
          y: {
            formatter: function (val) { 
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })  + "%"
            }
          }
        },
        xaxis: {
          categories: this.chartCategories,
        },
      };
      return tmpChartOptions;
    },
    chartOptionsCompany() {
      let self = this;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth / 2;
      } else {
        this.tmpWidth = window.innerWidth / 3;
      }
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          bar: {
            horizontal: true,
            columnWidth:'80%',
            dataLabels: {
              position: "top",
            },
          },
        },        
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        dataLabels: { 
          position: 'top',
          enabled: true,
          offsetX: 0,
          formatter: function (val) { 
            return val.toLocaleString("tr-TR", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })  + "%"
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true, 
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,  
          y: {
            formatter: function (val) { 
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })  + "%"
            }
          }
        },
        xaxis: {
          categories: this.chartCategoriesCompany,
        },
      };
      return tmpChartOptions;
    },
  },

  methods: {
    changeChart(){
      this.change_chart = !this.change_chart
    },
    resize() {
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 350;
        this.tmpWidth = window.innerWidth / 2;
        this.$emit("resizecard", "piechart");
      } else {
        this.height = 350;
        this.tmpWidth = window.innerWidth / 3;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;

      //teams
      this.series = [];
      this.chartCategories = [];
      this.expenseData = [];
      this.endorsementData = [];

      //companies
      this.seriesCompany = [];
      this.chartCategoriesCompany = [];
      this.expenseDataCompany = [];
      this.endorsementDataCompany = [];

      let filters = this.filters;

      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.endDate).format(LARAVEL_DATE_FORMAT)
      ); 

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/profit-and-loss-percent-chart-report",
        })
        .then((result) => {
          this.chartLoading = false;
          let self = this;
          if (result.status) {
            this.chartItems = result.data.chart_items;
            this.chartItems.forEach((chartData, key) => {
              self.chartCategories.push(chartData.name);
              self.expenseData.push(chartData.expense_ratio);
              self.endorsementData.push(chartData.endorsement_ratio);
            });
            this.series = [
              {
                name: "Kar-Zarar Oranı",
                data: this.endorsementData,
                color: "#2BAA1E",
              },
              {
                name: "Toplam Adetteki Oranı",
                data: this.expenseData,
                color: "#6563A4",
              },
            ];  


            this.chartItemsCompany = result.data.company_chart_items;
            this.chartItemsCompany.forEach((chartData, key) => {
              self.chartCategoriesCompany.push(chartData.name);
              self.expenseDataCompany.push(chartData.expense_ratio);
              self.endorsementDataCompany.push(chartData.endorsement_ratio);
            });
            this.seriesCompany = [
              {
                name: "Kar-Zarar Oranı",
                data: this.endorsementDataCompany,
                color: "#2BAA1E",
              },
              {
                name: "Toplam Adetteki Oranı",
                data: this.expenseDataCompany,
                color: "#6563A4",
              },
            ];  

          } else {
            let tmpresult = result.data.response.data;
            this.sweetAlertError(tmpresult.message);
          }
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if (this.chartLoading != true) {
          this.series = [];
          this.labels = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.profit-loss-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 200px;
}
</style>
